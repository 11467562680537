import { DBFactory } from "~/classes/database/db_factory";
import { Classroom, Classrooms } from "./classroom.model";
import { Observable, map } from "rxjs";
import { Assignment, Assignments } from "../assignments/assignment.model";
import {
  StudentLinkedAccount,
  StudentLinkedAccounts,
} from "../studentLinkedAccounts/student-linked-account.model";

export class ClassroomsService {
  static async get(id: string) {
    const db = DBFactory.createDatabase();

    const databaseData = await db.get({
      collection: "classrooms",
      path: `classrooms/${id}`,
    });

    return Classroom.fromMap(databaseData);
  }

  static async listAllClassrooms() {
    const db = DBFactory.createDatabase();

    const databaseData = await db.list({
      collection: `/classrooms`,
      path: `/classrooms`,
    });

    return databaseData.map((data) => {
      return Classroom.fromMap(data);
    });
  }

  static async listClassroomsToSync() {
    const limit = process.env.CLASSROOMS_SYNC_LIMIT
      ? parseInt(process.env.CLASSROOMS_SYNC_LIMIT)
      : 100;

    const db = DBFactory.createDatabase();

    const databaseData = await db.list(
      {
        collection: `/classrooms`,
        path: `/classrooms`,
      },
      [
        {
          type: "where",
          field: "externalProvider",
          operator: "in",
          value: ["sakai", "google", "canvas"],
        },
        {
          type: "where",
          field: "lastSyncedTimestamp",
          operator: "<",
          // Get's classrooms whose assignemnts haven't been synced in 10 hours
          value: Date.now() - 1000 * 60 * 60 * 10,
        },
        {
          type: "orderBy",
          field: "lastSyncedTimestamp",
          direction: "asc",
        },
      ]
    );

    const classrooms = databaseData
      .map((data) => {
        return Classroom.fromMap(data);
      })
      .filter((classroom) => classroom.isArchived != true);

    if (classrooms.length >= limit) return classrooms;

    const allClassrooms = await this.listAllClassrooms();
    const classroomsWithoutTimestamps = allClassrooms.filter((classroom) => {
      return (
        classroom.isArchived != true &&
        !classroom.lastSyncedTimestamp &&
        ["sakai", "google", "canvas"].includes(classroom.externalProvider)
      );
    });

    // Get the first 10 lmsUserConfigs that don't have a timestamp
    const classroomsWithoutTimestampsLimited =
      classroomsWithoutTimestamps.slice(0, limit - classrooms.length);

    return [...classrooms, ...classroomsWithoutTimestampsLimited];
  }

  static async listAllOpenAssments(): Promise<Assignments> {
    const db = DBFactory.createDatabase();

    const openStatuses = ["DUE", "OPEN"]; // I'll change it to ["OPEN", "DUE"] after I think

    const databaseData = await db.list(
      {
        collection: `/assignments`,
        path: `/assignments`,
      },
      [
        {
          type: "where",
          field: "status",
          operator: "in",
          value: openStatuses,
        },
      ]
    );

    return databaseData.map((data) => {
      return Assignment.fromMap(data);
    });
  }

  static async listAssignmentsToSync(
    ignoreLimit: boolean = false
  ): Promise<Assignments> {
    let limit = process.env.ASSIGNMENT_SYNC_LIMIT
      ? parseInt(process.env.ASSIGNMENT_SYNC_LIMIT)
      : 200;

    if (ignoreLimit) {
      limit = 1000000000;
    }

    const db = DBFactory.createDatabase();

    const openStatuses = ["DUE", "OPEN"]; // I'll change it to ["OPEN", "DUE"] after I think
    const lmsToAutoSync = ["sakai"];

    // we are no longer syncing assignments from google classroom automatically
    const queryData = [
      {
        type: "where",
        field: "status",
        operator: "in",
        value: openStatuses,
      },
      {
        type: "where",
        field: "externalProvider",
        operator: "in",
        value: lmsToAutoSync,
      },
      {
        type: "orderBy",
        field: "lastSyncedTimestamp",
        direction: "asc",
      },
    ] as ModelQueryConfig;

    if (ignoreLimit != true) {
      queryData.push({
        type: "where",
        field: "lastSyncedTimestamp",
        operator: "<",
        // Get's classrooms whose assignemnts haven't been synced in 30 minutes
        value: Date.now() - 1000 * 60 * 30,
      });

      queryData.push({
        type: "limit",
        value: limit,
      });
    }

    const databaseData = await db.list(
      {
        collection: `/assignments`,
        path: `/assignments`,
      },
      queryData
    );

    const assignments = databaseData
      .map((data) => {
        return Assignment.fromMap(data);
      })
      .filter((assignment) => assignment.isArchived != true);

    if (assignments.length >= limit) return assignments;

    const allAssignments = await this.listAllOpenAssments();
    const assignmentsWithoutTimestamps = allAssignments.filter((assignment) => {
      return (
        !assignment.lastSyncedTimestamp &&
        lmsToAutoSync.includes(assignment.externalProvider) &&
        assignment.isArchived != true
      );
    });

    // Get the first 10 lmsUserConfigs that don't have a timestamp
    const assignmentsWithoutTimestampsLimited =
      assignmentsWithoutTimestamps.slice(0, limit - assignments.length);

    return [...assignments, ...assignmentsWithoutTimestampsLimited];
  }

  static async listUserClassrooms(userId: string) {
    const db = DBFactory.createDatabase();

    const databaseData = await db.list(
      {
        collection: `/classrooms`,
        path: `/classrooms`,
      },
      this.buildUserClassroomQuery(userId)
    );

    return databaseData.map((data) => {
      try {
        Classroom.fromMap(data);
      } catch (error) {
        // empty
      }

      return Classroom.fromMap(data);
    });
  }

  static async getUserClassrooms(userId: string) {
    const db = DBFactory.createDatabase();

    const data = await db.list(
      {
        collection: `/classrooms`,
        path: `/classrooms`,
      },
      this.buildUserClassroomQuery(userId)
    );

    return data.map((data) => {
      return Classroom.fromMap(data);
    });
  }

  static streamUserClassrooms(userId: string) {
    const db = DBFactory.createDatabase();

    return db
      .streamList(
        {
          collection: `/classrooms`,
          path: `/classrooms`,
        },
        this.buildUserClassroomQuery(userId)
      )
      .pipe(
        map((databaseData) => {
          return databaseData.map((data) => Classroom.fromMap(data));
        })
      );
  }

  static buildUserClassroomQuery(userId: string) {
    return [
      {
        type: "where",
        field: "userId",
        operator: "==",
        value: userId,
      },
      {
        type: "orderBy",
        field: "name",
        direction: "asc",
      },
    ] as ModelQueryConfig;
  }

  static async saveClassrooms(classrooms: Classrooms) {
    const db = DBFactory.createDatabase();

    await db.batchUpdate(classrooms);
  }

  static async deleteClassrooms(classrooms: Classrooms) {
    const db = DBFactory.createDatabase();

    await db.batchDelete(classrooms);
  }

  static getLMSUserConfig = async (userId: string) => {
    const db = DBFactory.createDatabase();

    const lmsUserConfig = await db.get({
      collection: "lmsUserConfigs",
      path: `lmsUserConfigs/${userId}`,
    });

    return lmsUserConfig as LMSUserConfig | undefined;
  };

  static streamAllStudentLinkedAccounts({
    userId,
  }: {
    userId: string;
  }): Observable<StudentLinkedAccounts> {
    const db = DBFactory.createDatabase();

    return db
      .streamList(
        {
          collectionGroup: "linkedAccounts",
          collection: "linkedAccounts",
          path: `linkedAccounts`,
        },
        [
          {
            type: "where",
            field: "userId",
            operator: "==",
            value: userId,
          },
          {
            type: "where",
            field: "isArchived",
            operator: "==",
            value: false,
          },
        ]
      )
      .pipe(
        map((databaseData) => {
          return databaseData.map((data) => {
            return StudentLinkedAccount.fromMap(data);
          });
        })
      );
  }
}
